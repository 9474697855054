var default_width = window.innerWidth;//アクセスした時の横幅を取得 スクロールバーを含めた値
var resize_width = window.innerWidth;
var break_point = 1150;
$(function() {
	var default_height = $(document).innerHeight();//アクセスした時の高さを取得


  //スムーズスクロール（ページ内リンク）
  var headerHeight = $('.header').outerHeight();
  var urlHash = location.hash;
  if(urlHash) {
      $('body,html').stop().scrollTop(0);
      setTimeout(function(){
          var target = $(urlHash);
          var position = target.offset().top - headerHeight - 50;
          $('body,html').stop().animate({scrollTop:position}, 600,"swing");
      }, 100);
  }

  $("a[href^='#'], .js-scroll").on("click", function () {
    var f = 600;
    var e = $(this).attr("href");
    var g = $(e == "#" || e == "" ? "html" : e);
    var h = g.offset().top - headerHeight - 50;
    $("html, body").animate({
      scrollTop: h
    }, f, "swing");
    return false
  });

  //qa開閉
  // $(".categoryTtl").on('click',function() {
  //   // if (default_width <= break_point) {
  //     $(this).next(".categoryCon").slideToggle(300);
  //     $(this).toggleClass("active");
  //   // }
  // });


  //inview（画面内に入ったら発火する）
  var ignition = function() { 
    $('.js-inview').on('inview', function(event, isInView, visiblePartX, visiblePartY) {
      $(this).stop().addClass('active');
    });
  };
  var bandText = function() { 
    $('.passing').on('inview', function(event, isInView, visiblePartX, visiblePartY) {
      $(this).stop().addClass("move");
    });
  };
  setTimeout(function() {
    ignition();
  },1000);
  setTimeout(function() {
    bandText();
  },300);


	/*------------------------*/
	//バーガーメニュー表示・非表示
	/*------------------------*/
	//バーガーボタンクリック
	$(".burger").on('click',function() {
		default_width = window.innerWidth;//クリックされた時の数値をdefault_widthに設定
		if (default_width <= break_point) {
			$(".burger").toggleClass("is-open");
      $(".gNav").toggleClass("active");
			if ($(this).hasClass("is-open")) { //バーガーメニューが開かれた状態か
        $(".headerNav__cover").removeClass("hide").addClass("show");
			} else {
        $(".headerNav__cover").addClass("hide").removeClass("show");
			}
		}
	});
  //カバーをクリック
	$(".headerNav__cover,.gNav a").on('click',function() {
    if (default_width <= break_point) {
      $(".gNav").removeClass("active");
  		$(".burger").removeClass("is-open");
      $(".headerNav__cover").addClass("hide").removeClass("show");
    }
  });
  $(".pullDownHover").on('click',function(){
		if (default_width <= break_point) {
			$(this).toggleClass('active');
			$(this).find(".pullDown").slideToggle(400);
		}
  });
  
});

$(window).on('load',function() {
  $("body").addClass("load");
});
/*------------------------*/
//関数
/*------------------------*/
/*スライダー*/
var sliderMv = function() {
	$(function() {
		//slider設置
    var $slider = $('.slider--mv');
    var slideCount = $('.slider--mv .listItem').length;
    if (slideCount >= 2) {
      $slider.slick({
        accessibility: false,
        arrows: false,
        dots: false,
        slidesToShow: 1,
        draggable: false,
        fade: true,
        autoplay: true,
        autoplaySpeed: 1500,
        speed: 1000
      });
    }
	});
}



$(window).on("scroll", function () {

  default_width = window.innerWidth;//クリックされた時の数値をdefault_widthに設定
  if (break_point <= default_width) {
    scrollHeight = $(document).height();
    scrollPosition = $(window).height() + $(window).scrollTop();
    footerHeight = $("footer").innerHeight();
    if (scrollHeight - scrollPosition <= footerHeight) {
      $(".btn-360view").css({
        "position": "absolute",
        "bottom": footerHeight
      });
    }
    else {
      $(".btn-360view").css({
        "position": "fixed",
        "bottom": "3%",
      });
    }

  } else {

    scrollHeight = $(document).height();
    scrollPosition = $(window).height() + $(window).scrollTop();
    footerHeight = $("footer").innerHeight();
    if (scrollHeight - scrollPosition <= footerHeight) {
      $(".btn-360view").css({
        "position": "absolute",
        "bottom": "20px"
      });
    }
    else {
      $(".btn-360view").css({
        "position": "fixed",
        "bottom": "3%",
      });
    }

  }

});





